//import logo from './logo.svg';
//import './App.css';
//import DataTable from './components/DataTable';
//
//function App() {
//  return (
//    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
//      <h1>PV Data Dashboard</h1>
//      <DataTable />
//    </div>
//  );
//}
//
//export default App;


//import React, { useState, useEffect } from "react";
//import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from "recharts";
//
//function App() {
//  const [data, setData] = useState([]);
//  const [showTable, setShowTable] = useState(true); // State to toggle the table
//
//  useEffect(() => {
//      fetch("http://206.189.11.119:4000/api/pv-data")
//          .then((response) => response.json())
//          .then((result) => setData(result))
//          .catch((error) => console.error("Error fetching data:", error));
//  }, []);
//    return (
//      <div>
//          <h1>Electrical Measurements</h1>
//          
//          {/* Button to toggle table visibility */}
//          <button onClick={() => setShowTable(!showTable)}>
//              {showTable ? "Hide Table" : "Show Table"}
//          </button>
//
//          {/* Conditionally render the table */}
//          {showTable && <DataTable data={data} />}
//
//          <DataChart data={data} />
//      </div>
//  );
//}
//
//function DataTable({ data }) {
//    if (!data || data.length === 0) return <div>No data available</div>;
//
//    return (
//        <table border="1" style={{ width: "100%", textAlign: "center", margin: "20px 0" }}>
//            <thead>
//                <tr>
//                    <th>Timestamp</th>
//                    <th>Active Power</th>
//                    <th>V1</th>
//                    <th>V2</th>
//                    <th>V3</th>
//                </tr>
//            </thead>
//            <tbody>
//                {data.map((row) => (
//                    <tr key={row.id}>
//                        <td>{new Date(row.timestamp).toLocaleString()}</td>
//                        <td>{row.Active_Power}</td>
//                        <td>{row.V1}</td>
//                        <td>{row.V2}</td>
//                        <td>{row.V3}</td>
//                    </tr>
//                ))}
//            </tbody>
//        </table>
//    );
//}
//
//function DataChart({ data }) {
//    if (!data || data.length === 0) return <div>Loading Chart...</div>;
//
//    return (
//        <LineChart width={1000} height={500} data={data}>
//            <CartesianGrid stroke="#ccc" />
//            <XAxis dataKey="timestamp" tickFormatter={(time) => new Date(time).toLocaleTimeString()} />
//            <YAxis />
//            <Tooltip labelFormatter={(time) => new Date(time).toLocaleString()} />
//            <Legend />
//            <Line type="monotone" dataKey="Active_Power" stroke="#8884d8" />
//            <Line type="monotone" dataKey="V1" stroke="#82ca9d" />
//            <Line type="monotone" dataKey="V2" stroke="#ffc658" />
//            <Line type="monotone" dataKey="V3" stroke="#ff7300" />
//        </LineChart>
//    );
//}
//
//export default App;


import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from "recharts";

function App() {
    const [data, setData] = useState([]);
    const [showTable, setShowTable] = useState(true);

    useEffect(() => {
        fetch("https://api.cloudtoenergy.gr/api/pv-data")
            .then((response) => response.json())
            .then((result) => setData(result))
            .catch((error) => console.error("Error fetching data:", error));
    }, []);

    return (
        <div>
            <h1>Electrical Measurements</h1>

            <button onClick={() => setShowTable(!showTable)}>
                {showTable ? "Hide Table" : "Show Table"}
            </button>

            {showTable && <DataTable data={data} />}

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                <div style={{ flex: "1", marginRight: "10px" }}>
                    <h2>Active Power Trend</h2>
                    <ActivePowerChart data={data} />
                </div>
                <div style={{ flex: "1", marginLeft: "10px" }}>
                    <h2>Voltage Trends</h2>
                    <VoltageChart data={data} />
                </div>
            </div>
        </div>
    );
}

function DataTable({ data }) {
    if (!data || data.length === 0) return <div>No data available</div>;

    return (
        <table border="1" style={{ width: "100%", textAlign: "center", margin: "20px 0" }}>
            <thead>
                <tr>
                    <th>Timestamp</th>
                    <th>Active Power</th>
                    <th>V1</th>
                    <th>V2</th>
                    <th>V3</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row) => (
                    <tr key={row.id}>
                        <td>{new Date(row.timestamp).toLocaleString()}</td>
                        <td>{row.Active_Power}</td>
                        <td>{row.V1}</td>
                        <td>{row.V2}</td>
                        <td>{row.V3}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function ActivePowerChart({ data }) {
    if (!data || data.length === 0) return <div>Loading Chart...</div>;

    return (
        <LineChart width={500} height={400} data={data}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="timestamp" tickFormatter={(time) => new Date(time).toLocaleTimeString()} />
            <YAxis />
            <Tooltip labelFormatter={(time) => new Date(time).toLocaleString()} />
            <Legend />
            <Line type="monotone" dataKey="Active_Power" stroke="#8884d8" />
        </LineChart>
    );
}

function VoltageChart({ data }) {
    if (!data || data.length === 0) return <div>Loading Chart...</div>;

    return (
        <LineChart width={500} height={400} data={data}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="timestamp" tickFormatter={(time) => new Date(time).toLocaleTimeString()} />
            <YAxis />
            <Tooltip labelFormatter={(time) => new Date(time).toLocaleString()} />
            <Legend />
            <Line type="monotone" dataKey="V1" stroke="#82ca9d" />
            <Line type="monotone" dataKey="V2" stroke="#ffc658" />
            <Line type="monotone" dataKey="V3" stroke="#ff7300" />
        </LineChart>
    );
}

export default App;
